



































import moment from 'moment';
import Vue from 'vue';
import { handleNewsUrl } from '@/util/common';

export default Vue.extend({
  name: 'NewsList',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'news',
    },
    idx: {
      type: Number,
      default: 1,
    },
  },
  filters: {
    formatTime(val: string) {
      return moment(new Date(val)).format('YYYY-MM-DD');
    },
  },
  methods: {
    handleNewsUrl,
  },
});
