






































































































import Vue from 'vue';
import list from './topData';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  async asyncData(ctx) {
    try {
      let loading = true;
      const fairList: any = [];
      const resList: any[] = [];
      list.slice(0, 10).forEach((id) => {
        resList.push(ctx.$axios.get(`/api/exhibition/${id}`));
      });

      const result = await Promise.all(resList);
      loading = false;
      result.forEach((res) => {
        if (res.code === ResponseCode.SUCCESS) {
          fairList.push(res.data || {});
        } else {
          fairList.push({});
        }
      });

      return {
        fairList: fairList.filter((item: {}) => Object.keys(item).length > 0),
        page: 1,
        loading,
      };
    } catch (error) {
      if (process.client && 'fundebug' in window) {
        window.fundebug.notifyError(error);
      }
      console.error(error);
    }
  },
  data() {
    return {
      fairList: [],
      page: 1,
      loading: true,
    };
  },
  methods: {
    async getList() {
      const { page } = this.$data;
      const fairList: any = [];
      const resList: any[] = [];
      list.slice((page - 1) * 10, page * 10).forEach((id) => {
        resList.push(this.$axios.get(`/api/exhibition/${id}`));
      });
      this.loading = true;
      console.time('request');
      const result = await Promise.all(resList);
      console.timeEnd('request');
      this.loading = false;
      result.forEach((res) => {
        if (res.code === ResponseCode.SUCCESS) {
          fairList.push(res.data);
        }
      });

      this.fairList = fairList;
    },
    handlePageChange(page: number) {
      this.page = page;
      this.getList();
    },
  },
  head() {
    return {
      title: `${new Date().getFullYear()}年世界商展100大排行榜-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '100大商业展会,展会排行榜',
        },
        {
          name: 'description',
          content:
            '聚展网是国内专业国际展会一站式服务平台，帮助企业便捷参加全球展会，国外展会，企业首选的参展服务平台，公司已带领上千家企业出国参展，足迹遍布欧、美、亚、非、澳五大洲主流会展名城，为中国对外出口贸易做出重要贡献。',
        },
      ],
    };
  },
});
