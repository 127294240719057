






import Vue from 'vue';
import FairItem from './FairItem.vue';

export default Vue.extend({
  name: 'EveFair',
  components: {
    FairItem,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
});
